<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Calendar and Sidebar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <div class="" :class="{ show: isCalendarOverlaySidebarActive }">
              <calendar-sidebar
                :is-event-handler-sidebar-active.sync="
                  isEventHandlerSidebarActive
                "
              />
            </div>

            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BButton,
  BFormFile,
  BFormTextarea,
  BRow,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import calendarStoreModule from "./calendarStoreModule";
import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";
import useCalendar from "./useCalendar";
import calendarLocale from "@/@core/utils/calendar-locale";
import router from "@/router";

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BFormFile,
    BFormTextarea,
    BRow,
    BFormSelect,
    vSelect,
    FullCalendar,
    CalendarSidebar,
    CalendarEventHandler,
  },

  watch: {
    "$i18n.locale": function () {
      this.refreshLocale();
    },
  },

  data() {
    return {
      calendarData: [],
      userData: store.state.user.userData,
    };
  },

  async created() {
    //await this.loadChartData();
  },

  methods: {
    refreshLocale() {
      this.calendarOptions.locale = calendarLocale(this.$i18n.locale);
    },

    /*async loadChartData() {
      await axios
        .get(`${URL_API}chart/lateCalendar/all`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.calendarData = JSON.parse(response.data.chartInfo); // Json to object
     
        });
    },*/
  },

  setup() {
    //const { t } = useI18nUtils();

    const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
    });

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar();

    // Get current locale from setup()
    //console.log(router.app.$i18n.locale);
    calendarOptions.value.locale = calendarLocale(router.app.$i18n.locale);

    fetchEvents();

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    };
  },
};
</script>

<style></style>
