<template>
  <div>
    <div>
      <h2>
        {{ $t("timeSheetCalendarTitle") }}
        <small class="float-right">{{
          localeDate(jobTimestamp) || "Dados não carregados"
        }}</small>
      </h2>

      <div class="mt-2">
        <b-row class="customizer-toggle justify-content-center">
          <b-form-checkbox v-model="checkAll" class="mb-1 mr-2">
            {{ $t("message.dashboardAdm.seeAll") }}
          </b-form-checkbox>

          <b-form-group>
            <b-form-checkbox-group
              v-model="selectedCalendars"
              name="event-filter"
            >
              <b-form-checkbox
                v-for="item in calendarOptions"
                :key="item.label"
                name="event-filter"
                :value="item.label"
                class="mb-1"
                :class="`custom-control-${item.color}`"
              >
                {{ item.label }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
  BRow,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useCalendarSidebar from "./useCalendarSidebar";
import moment from "moment";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BRow,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },

  data() {
    return {
      jobTimestamp: JSON.parse(localStorage.getItem("jobTimestamp")),
    };
  },

  methods: {
    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },
  },

  setup() {
    const { calendarOptions, selectedCalendars, checkAll } =
      useCalendarSidebar();

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
    };
  },
};
</script>

<style></style>
