/* eslint-disable */
import { ref, watch, computed } from "@vue/composition-api";
export default function useTimeSheetsList() {
  const refTimeSheetListTable = ref(null);

  const perPage = ref(10);
  const totalTimeSheets = ref(0);
  const currentPage = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refTimeSheetListTable.value
      ? refTimeSheetListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTimeSheets.value,
    };
  });

  return {
    perPage,
    currentPage,
    totalTimeSheets,
    dataMeta,
    refTimeSheetListTable,
  };
}
