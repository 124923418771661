<template>
  <div>
    <timeSheet-list-add-new
      :is-add-new-time-sheet-sidebar-active.sync="
        isAddNewTimeSheetSidebarActive
      "
      :project-options="projectOptions"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div
              v-bind:class="{
                'align-items-center justify-content-end': isActive,
                'd-flex align-items-center justify-content-end': !isActive,
              }"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="searchQuery"
                  @keyup.enter="searchWord()"
                  :placeholder="$t('message.bookmark.search')"
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    @click="searchWord()"
                    disabled
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <br v-if="isActive" />
              <b-dropdown
                right
                variant="gradient-warning"
                class="mr-1"
                v-if="restrictions('button_export_data')"
              >
                <template slot="button-content">
                  <feather-icon icon="DownloadIcon" />
                </template>
                <b-dropdown-item
                  ><download-excel
                    :data="timeSheetList"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.dataInView')"
                  >
                    {{ $t("message.buttons.dataInView") }}
                  </download-excel></b-dropdown-item
                >
                <b-dropdown-item
                  ><download-excel
                    :fetch="allData"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.allData')"
                  >
                    {{ $t("message.buttons.allData") }}
                  </download-excel></b-dropdown-item
                >
              </b-dropdown>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="secondary"
                class="btn-icon mr-1"
                v-b-modal.modal-timeSheet-calendar
                v-if="restrictions('button_timesheet_calendar')"
              >
                <feather-icon icon="CalendarIcon" />
              </b-button>

              <b-button
                variant="primary"
                @click="isAddNewTimeSheetSidebarActive = true"
                v-if="restrictions('button_new_time_sheet')"
              >
                <span class="text-nowrap">{{
                  $t("message.buttons.newRelease")
                }}</span>
              </b-button>
              <b-button v-else variant="primary" disabled>
                <span class="text-nowrap">{{
                  $t("message.buttons.newRelease")
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="my-table"
        ref="refTimeSheetListTable"
        class="position-relative"
        :items="timeSheetList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :busy="isBusy"
        show-empty
        @row-clicked="openViewPage"
        hover
      >
        <template #table-busy>
          <div class="text-center my-17">
            <b-spinner class="align-middle"></b-spinner>
            <strong> {{ $t("message.load") }}...</strong>
          </div>
        </template>

        <template slot="empty">
          <div class="text-center my-5">
            {{ $t("message.registerNotFound") }}
          </div>
        </template>

        <!-- Column: Date -->
        <template #cell(Data)="data">
          {{ localeDate(data.item.date) }}
        </template>

        <!-- Column: User -->
        <template #cell(Usuário)="data">
          {{ data.item.userEmail }}
        </template>

        <!-- Column: Project -->
        <template #cell(Projeto)="data">
          {{ data.item.projectName }}
        </template>

        <!-- Column: Task -->
        <template #cell(Tarefa)="data">
          {{ data.item.taskName }}
        </template>

        <!-- Column: Hour -->
        <template #cell(Horas)="data">
          {{ data.item.hoursQuantity }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t("message.pagelength") }} {{ dataMeta.from }}
              {{ $t("message.of") }} {{ dataMeta.to }} {{ $t("message.of") }}
              {{ totalRegisters }} {{ $t("register") }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            v-if="showPagination"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @input="eventPage(currentPage)"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Calendar Modal -->

    <b-modal
      id="modal-timeSheet-calendar"
      modal-class="modal-primary"
      centered
      :title="$t('calendar')"
      hide-footer
      size="lg"
    >
      <TimeSheetCalendar></TimeSheetCalendar>
    </b-modal>

    <!-- Calendar Modal -->
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useTimeSheetsList from "./useTimeSheetsList";
import TimeSheetListAddNew from "./TimeSheetListAddNew.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import JsonExcel from "vue-json-excel";
import Vue from "vue";
import AccessControl from "@core/utils/access-control";
import ListWidth from "@core/utils/listWidth";
import TimeSheetCalendar from "../../charts/TimeSheet/TimeSheetCalendar.vue";
import store from "@/store";
Vue.component("downloadExcel", JsonExcel);

export default {
  components: {
    TimeSheetListAddNew,
    TimeSheetCalendar,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      timeSheetList: [],
      totalRegisters: 0,
      perPage: 0,
      currentPage: 1,
      isBusy: false,
      showPagination: true,
      allDataSize: 9999,

      //Select
      projectOptions: [],

      //Param
      paramData: store.state.user.paramData,

      //CurrentUser
      userData: store.state.user.userData,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,
    };
  },

  computed: {
    tableColumns() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }

      return [
        { key: "Data", sortable: false, label: this.getColLabel("date") },
        { key: "Usuário", sortable: false, label: this.getColLabel("user") },
        { key: "Projeto", sortable: false, label: this.getColLabel("project") },
        { key: "Tarefa", sortable: false, label: this.getColLabel("task") },
        { key: "Horas", sortable: false, label: this.getColLabel("hours") },
      ];
    },
  },

  async created() {
    this.onResize();
    await this.getTimeSheetList();

    var teamsByMember = [];
    await axios
      .get(`${URL_API}team/teamsByMember/${this.userData.id}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.content.length; i++) {
          teamsByMember.push(response.data.content[i].id);
        }
      });

    if (this.restrictions("timesheet-list-all")) {
      var projectList = [];
      var projectId = [];
      await axios
        .get(`${URL_API}project/select_list`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i].name;
            const id = response.data[i].id;
            projectList.push(element);
            projectId.push(id);
            this.projectOptions.push({
              label: projectList[i],
              value: projectId[i],
            });
          }
        });
    } else {
      var projectList = [];
      var projectId = [];
      await axios
        .get(`${URL_API}project/${teamsByMember}/selected_list`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i].name;
            const id = response.data[i].id;
            projectList.push(element);
            projectId.push(id);
            this.projectOptions.push({
              label: projectList[i],
              value: projectId[i],
            });
          }
        });
    }
  },

  mounted() {
    this.$root.$on("newListTimeSheet", (newListTimeSheet) => {
      this.isBusy = true;

      if (this.restrictions("timesheet-list-all")) {
        axios
          .get(
            `${URL_API}timeSheet/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.isBusy = false;
            this.timeSheetList = response.data.content;
            this.totalRegisters = response.data.totalElements;
            this.perPage = response.data.numberOfElements;
          });
      } else {
        axios
          .get(
            `${URL_API}timeSheet/${this.userData.id}/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.isBusy = false;
            this.timeSheetList = response.data.content;
            this.totalRegisters = response.data.totalElements;
            this.perPage = response.data.numberOfElements;
          });
      }
      this.currentPage = 1;
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    restrictions(value) {
      return AccessControl(value);
    },

    getColLabel(colName) {
      return this.$i18n.t("message.list." + colName);
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.isActive = ListWidth(this.windowWidth);
    },

    async allData() {
      const response = await axios.get(
        `${URL_API}timeSheet/list?page=0&sort=id,${this.paramData.listSort}&size=${this.allDataSize}`,
        { headers: getHeader(this.userData) }
      );
      return response.data.content;
    },

    openViewPage(row) {
      row._showDetails = !row._showDetails;
      this.$router.push({
        name: "apps-time-sheet-view",
        params: { id: row.id },
      });
    },

    startDownload() {
      this.isBusy = true;
    },
    finishDownload() {
      this.isBusy = false;
    },

    async getTimeSheetList() {
      if (this.restrictions("timesheet-list-all")) {
        this.isBusy = true;
        await axios
          .get(
            `${URL_API}timeSheet/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.totalRegisters = response.data.totalElements;
            this.timeSheetList = response.data.content;
            this.perPage = response.data.numberOfElements;
            this.isBusy = false;
          });
      } else {
        this.isBusy = true;
        await axios
          .get(
            `${URL_API}timeSheet/${this.userData.id}/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.totalRegisters = response.data.totalElements;
            this.timeSheetList = response.data.content;
            this.perPage = response.data.numberOfElements;
            this.isBusy = false;
          });
      }

      if (this.timeSheetList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
        this.currentPage = 1;
      }
    },

    async eventPage(currentPage) {
      this.isBusy = true;

      if (this.restrictions("timesheet-list-all")) {
        await axios
          .get(
            `${URL_API}timeSheet/list-dto?page=${currentPage - 1}&sort=id,${
              this.paramData.listSort
            }&size=${this.paramData.tableSize}`,
            { headers: getHeader(this.userData) }
          )
          .then((response) => {
            this.timeSheetList = response.data.content;
            this.isBusy = false;
          });
      } else {
        await axios
          .get(
            `${URL_API}timeSheet/${this.userData.id}/list-dto?page=${
              currentPage - 1
            }&sort=id,${this.paramData.listSort}&size=${
              this.paramData.tableSize
            }`,
            { headers: getHeader(this.userData) }
          )
          .then((response) => {
            this.timeSheetList = response.data.content;
            this.isBusy = false;
          });
      }

      if (this.timeSheetList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
      }
    },

    async getTimeSheetFilter() {
      this.isBusy = true;
      await axios
        .get(`${URL_API}timeSheet/search?searchTerm=${this.searchQuery}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.timeSheetList = [];
          if (response.data != []) {
            for (var i = 0; i < response.data.length; i++) {
              this.timeSheetList.push(response.data[i]);
            }
          }
          this.isBusy = false;
          this.showPagination = false;
        });
    },

    searchWord() {
      if (this.searchQuery) {
        this.getTimeSheetFilter();
      } else {
        this.getTimeSheetList();
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },
  },

  setup() {
    const isAddNewTimeSheetSidebarActive = ref(false);

    const {
      perPage,
      currentPage,
      totalTimeSheets,
      dataMeta,
      searchQuery,
      refTimeSheetListTable,
    } = useTimeSheetsList();

    return {
      isAddNewTimeSheetSidebarActive,
      perPage,
      currentPage,
      totalTimeSheets,
      dataMeta,
      searchQuery,
      refTimeSheetListTable,
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.my-17 {
  margin-top: 17.2rem;
  margin-bottom: 17.2rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
