import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import AccessControl from "@core/utils/access-control";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: "success",
        label: "Completo",
      },
      {
        color: "warning",
        label: "Incompleto",
      },
    ],
    selectedCalendars: ["Completo", "Incompleto"],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val;
    },
  },
  actions: {
    fetchEvents(ctx, { calendars }) {
      return new Promise((resolve, reject) => {
        //CurrentUser
        var userData = store.state.user.userData;

        if (calendars.length === 0) {
          //Se não tiver nenhum filtro selecionado
          let res = new Response();
          res.data = "";

          resolve(res);
        } else {
          if (AccessControl("calendar_timesheet_all")) {
            axios
              .get(`${URL_API}chart/lateCalendar/${calendars}`, {
                headers: getHeader(userData),
              })
              .then((response) => {
                if (response.data != "") {
                  localStorage.setItem(
                    "jobTimestamp",
                    JSON.stringify(response.data.timestamp)
                  ); //Timestamp
                  var obj = JSON.parse(response.data.chartInfo);
                  response.data = obj;
                  resolve(response);
                }
              })
              .catch((error) => reject(error));
          } else {
            axios
              .get(
                `${URL_API}chart/lateCalendar/${calendars}/${userData.id}/user`,
                {
                  headers: getHeader(userData),
                }
              )
              .then((response) => {
                if (response.data != "") {
                  localStorage.setItem(
                    "jobTimestamp",
                    JSON.stringify(response.data.timestamp)
                  );
                  var obj = JSON.parse(response.data.chartInfo);
                  response.data = obj;
                  resolve(response);
                }
              })
              .catch((error) => reject(error));
          }
        }
      });
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: `${URL_API}calendar`,
          headers: getHeader(userData),
          data: event,
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "put",
          url: `${URL_API}calendar/${event.id}`,
          headers: getHeader(userData),
          data: event,
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "delete",
          url: `${URL_API}calendar/${id}`,
          headers: getHeader(userData),
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
