<template>
  <b-sidebar
    id="add-new-timeSheet-sidebar"
    :visible="isAddNewTimeSheetSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-time-sheet-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("message.timeSheet.createNewRelease") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewTimeSheet())"
          @reset.prevent="resetForm"
        >
          <!-- Date -->
          <validation-provider
            #default="validationContext"
            name="data"
            rules="required"
          >
            <b-form-group label-for="date">
              <template v-slot:label>
                {{ $t("message.tableHeader.date") }}
              </template>
              <flat-pickr
                v-model="timeSheetData.date"
                class="form-control"
                :config="flatConfig"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.dateRequired") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Project -->
          <validation-provider
            #default="validationContext"
            name="projeto"
            rules="required"
          >
            <b-form-group
              label-for="project"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.project") }}
              </template>
              <v-select
                v-model="timeSheetData.projectId"
                @input="projectTasks(timeSheetData.projectId)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="projectOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="timeSheet-role"
              />
            </b-form-group>
          </validation-provider>

          <!-- Task -->
          <validation-provider
            #default="validationContext"
            name="tarefas"
            rules="required"
          >
            <b-form-group
              label-for="task"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("task") }}
              </template>
              <b-form-input v-if="taskList.length === 0" readonly />
              <v-select
                v-else
                v-model="timeSheetData.taskId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="taskList"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="timeSheet-role"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template v-slot:label>
                  {{ $t("message.list.fieldRequired") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- HoursQuantity -->
          <validation-provider
            #default="validationContext"
            name="horas"
            rules="required"
          >
            <b-form-group label-for="hoursQuantity">
              <template v-slot:label>
                {{ $t("message.date.hours") }}
              </template>
              <number-input
                v-model="timeSheetDataHoursQuantity"
                center
                controls
                :min="1"
                :max="24"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import date from "@/@core/utils/date";
import dateInput from "@/@core/utils/dateInput";
import moment from "moment";
import Vue from "vue";
import VueNumberInput from "@chenfengyuan/vue-number-input";
Vue.use(VueNumberInput);
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewTimeSheetSidebarActive",
    event: "update:is-add-new-time-sheet-sidebar-active",
  },
  props: {
    isAddNewTimeSheetSidebarActive: {
      type: Boolean,
      required: true,
    },
    projectOptions: {
      type: Array,
      required: true,
    },
    generalData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      required,
      alphaNum,
      timeSheetDate: null,
      flatConfig: {
        dateFormat: "d/m/Y",
      },
      taskList: [],
      subjectsAndProjectsList: [],

      currentEmployeerObj: store.state.user.employeeData,

      //Param
      paramData: store.state.user.paramData,

      //CurrentUser
      userData: store.state.user.userData,

      timeSheetDataHoursQuantity: 1,

      disabledButton: false,
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
    "_props.isAddNewTimeSheetSidebarActive": function (newVal, oldVal) {
      if (newVal) {
        this.timeSheetDataHoursQuantity = 0;
      }
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInput(this.$i18n.locale);
    }
  },

  async created() {
    await axios
      .get(`${URL_API}task/subjectsAndProjects`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        this.subjectsAndProjectsList = response.data;
      });
  },

  methods: {
    submitNewTimeSheet() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;

          this.timeSheetData.date = date(
            this.$i18n.locale,
            this.timeSheetData.date
          );
          this.timeSheetData.userId = this.userData.id;

          this.timeSheetData.hoursQuantity = this.timeSheetDataHoursQuantity;

          axios({
            method: "post",
            url: `${URL_API}timeSheet`,
            headers: getHeader(this.userData),
            data: this.timeSheetData,
          })
            .then(() => {
              this.updateTaskData(this.timeSheetData.hoursQuantity, this.timeSheetData.taskId);
              this.onSubmit();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.create"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: `Lançamento de horas criado com sucesso`,
                },
              });
            })
            .then(() => {
              this.$root.$emit("newListTimeSheet", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },

    updateTaskData(quantity, taskId) {
      var finalValue = 0;

      if (this.currentEmployeerObj.data.fixedCost != null) {
        var hourCost =
          Number(this.currentEmployeerObj.data.fixedCost) /
          this.paramData.hoursMonth;
        finalValue = hourCost * quantity;
      }

      axios({
        method: "put",
        url: `${URL_API}taskCost/${taskId}/${quantity}/${finalValue}`,
        headers: getHeader(this.userData),
      });
    },    

    currentDate() {
      this.timeSheetDate = Date(moment().locale(this.$i18n.locale));
    },

    async projectTasks(id) {
      this.taskList = [];
      this.timeSheetData.taskId = null;

      await axios
        .get(`${URL_API}task/subjectsAndProjects`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          for (var i = 0; i < this.subjectsAndProjectsList.length; i++) {
            if (
              this.subjectsAndProjectsList[i].projectId ===
              this.timeSheetData.projectId
            ) {
              this.taskList.push({
                label: this.subjectsAndProjectsList[i].subject,
                value: this.subjectsAndProjectsList[i].id,
              });
            }
          }
        });
    },
  },

  setup(props, { emit }) {
    const blankTimeSheetData = {
      date: "",
      projectId: "",
      taskId: "",
    };

    const timeSheetData = ref(JSON.parse(JSON.stringify(blankTimeSheetData)));
    const resettimeSheetData = () => {
      timeSheetData.value = JSON.parse(JSON.stringify(blankTimeSheetData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-time-sheet-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resettimeSheetData);

    return {
      timeSheetData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-timeSheet-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
